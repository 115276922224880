import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mode==='edit')?_c('div',{staticClass:"combo-box"},[_c('label',{staticClass:"text-left font-label"},[_vm._v(_vm._s(_vm.label))]),_c(VCombobox,_vm._g(_vm._b({attrs:{"value":_vm.value,"label":"","multiple":_vm.multiple,"hide-selected":"","hide-no-data":"","search-input":_vm.search,"delimiters":[','],"items":_vm.filteredItems},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"input":_vm.logicalUpdate},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c('span',{staticClass:"create-text"},[_vm._v("Create")]),_c(VChip,{attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.search)+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item[_vm.itemText] && item[_vm.itemText].trim())?_c(VChip,_vm._b({attrs:{"input-value":selected,"color":"#e5e7fa","label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2 chip-text notranslate"},[_vm._v(" "+_vm._s(item === Object(item) ? item[_vm.itemText] : item)+" ")]),(!_vm.notClearable)?_c(VIcon,{staticClass:"close-icon",attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("close")]):_vm._e()],1):_vm._e()]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(item[_vm.itemText] && item[_vm.itemText].trim())?_c(VChip,{attrs:{"dark":"","label":"","small":"","color":"#e5e7fa"}},[_c('span',{staticClass:"chip-text notranslate"},[_vm._v(" "+_vm._s(item[_vm.itemText])+" ")])]):_vm._e(),_c(VSpacer)]}}],null,false,1204495280)},'v-combobox',_vm.$attrs,false),_vm.$listeners))],1):_c('div',[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }