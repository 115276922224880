<template>
  <div class="combo-box" v-if="mode==='edit'">
    <label class="text-left font-label">{{ label }}</label>
    <v-combobox
      :value="value"
      label=""
      :multiple="multiple"
      hide-selected
      hide-no-data
      :search-input.sync="search"
      v-bind="$attrs"
      v-on="$listeners"
      @input="logicalUpdate"
      :delimiters="[',']"
      :items="filteredItems"
    >
      <template v-slot:no-data>
        <v-list-item>
          <span class="create-text">Create</span>
          <v-chip
            label
            small
          >
            {{ search }}
          </v-chip>
        </v-list-item>
      </template>
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          color="#e5e7fa"
          label
          small
          v-if="item[itemText] && item[itemText].trim()"
        >
          <span class="pr-2 chip-text notranslate">
            {{ item === Object(item) ? item[itemText] : item }}
          </span>
          <v-icon
            v-if="!notClearable"
            small
            @click="parent.selectItem(item)"
            class="close-icon"
          >close</v-icon>
        </v-chip>
      </template>
      <template v-slot:item="{ index, item }">
        <v-chip
          dark
          label
          small
          color="#e5e7fa"
          v-if="item[itemText] && item[itemText].trim()"
        >
          <span class="chip-text notranslate">
            {{ item[itemText] }}
          </span>
        </v-chip>
        <v-spacer></v-spacer>
      </template>
    </v-combobox>
</div>
<div v-else>
  <slot />
</div>
</template>

<script>
import _ from 'lodash';

export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    mode: {
      type: String,
      default: 'edit',
    },
    label: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    notClearable: {
      type: Boolean,
      default: false,
    },
    value: {
    },
  },
  computed: {
    itemText() {
      if (this.$attrs['item-text']) {
        return this.$attrs['item-text'];
      }
      return 'text';
    },
    itemValue() {
      if (this.$attrs['item-value']) {
        return this.$attrs['item-value'];
      }
      return 'value';
    },
    filteredItems() {
      return _.filter(this.$attrs.items, (item) => {
        if (item && Object(item)) {
          return item[this.itemText] && item[this.itemText].trim();
        }
        return item;
      });
    },
  },
  data: () => ({
    search: null,
  }),
  methods: {
    logicalUpdate(currentValue) {
      let currentValueIndex = -1;
      let itemIndex = -1;
      let createdIndex = -1;
      if (_.isArray(currentValue)) {
        currentValue.forEach((x, i) => {
          if (typeof x === 'string') {
            itemIndex = _.findIndex(this.$attrs.items, (y) => y[this.itemText] === x);
            if (itemIndex >= 0) {
              currentValueIndex = i;
            } else {
              createdIndex = i;
            }
          }
        });
        if (itemIndex >= 0) {
          const newValue = currentValue;
          newValue[currentValueIndex] = this.$attrs.items[itemIndex];
          this.$emit('change', newValue);
        }
        if (createdIndex >= 0) {
          const newValue = currentValue;
          newValue[createdIndex] = {
            [this.itemText]: currentValue[createdIndex],
          };
          this.$emit('change', newValue);
        }
        this.search = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .combo-box {
    margin-bottom: 20px;
  }
  .chip-text {
    color: $primary2;
  }
  .close-icon {
    color: $primary2 !important;
  }
  .create-text {
    margin-right: 5px;
  }
  ::v-deep .v-text-field {
    margin-top: 0;
  }
</style>
